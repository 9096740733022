import React, { useEffect, useRef, useState } from "react";
import { Accordion, Card, Dropdown } from "react-bootstrap";

import ApiCalling from "../../../network/ApiCalling";
import { Modal } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Util from "../../../Util";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";
import { useSelector } from "react-redux";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import EastSharpIcon from "@mui/icons-material/EastSharp";

const SectionStructure = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setIsToast } = props;
  const [stageData, setStageData] = useState([]);

  const [showRoomModal, setShowRoomModal] = useState(false);

  const [gradeId, setGradeId] = useState(0);

  const [roomId, setRoomId] = useState(0);
  const [roomTitle, setRoomTitle] = useState("");
  const [roomTitleError, setRoomTitleError] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [deleteRoomId, setDeleteRoomId] = useState(null);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [academicYear, setAcadamicyear] = useState("");

  const activeAcademicYear = useSelector(
    (state) => state?.academicReducer?.value
  );

  const closeRoomModal = () => {
    setShowRoomModal(false);
  };

  const stageList = async () => {
    setIsLoading(true);

    ApiCalling.apiCallBodyDataGet("stage/section")
      .then((res) => {
        // console.log("stage/section", res);
        setIsLoading(false);
        setStageData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    if (deleteRoomId !== null) {
      ApiCalling.apiCallDelete(`classroom/${deleteRoomId}`)
        .then((res) => {
          setIsLoading(false);
          setShowDeleteConfirmation(false);
          if (res.data.status === true) {
            setIsToast(t("Room Deleted Successfully"));
            setDeleteRoomId(null);
            stageList();
          } else {
            setIsToast(t(res.data.message));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  function addRoom(grade_id) {
    setGradeId(grade_id);
    setShowRoomModal(true);
    setRoomId(0);
    setRoomTitle("");
    setRoomTitleError("");
  }

  function editRoom(item) {
    setShowRoomModal(true);
    setRoomId(item.id);
    setRoomTitle(item.name);
    setRoomTitleError("");
  }

  const handleRoomSave = () => {
    setRoomTitleError("");
    var error = false;
    if (roomTitle == "") {
      setRoomTitleError(t("Room required"));
      error = true;
    }
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      if (roomId === 0) {
        const data = {
          name: roomTitle,
          grade_id: gradeId,
        };
        ApiCalling.apiCallBodyDataPost("classroom/add", data)
          .then((res) => {
            if (res.data.status === true) {
              stageList();
              setShowRoomModal(false);
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const data = {
          id: roomId,
          name: roomTitle,
        };
        ApiCalling.apiCallBodyDataPost("classroom/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setShowRoomModal(false);
              stageList();
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    stageList();
  }, [academicYear]);

  useEffect(() => {
    const data = Util.getAcadmicYear();
    if (data && data) {
      setAcadamicyear(data?.title);
    }
  }, [activeAcademicYear]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </a>
  ));

  function truncateClassName(className, maxLength) {
    if (className.length <= maxLength) {
      return className;
    } else {
      return className.substring(0, maxLength - 3) + "...";
    }
  }

  return (
    <div className="container-fluid">
      <div className="row mt-4 mb-2">
        <div className="col text-start">
          <h2 className="page-head px-3">
            {t("Section Structure for Academic Year")} -{" "}
            <span>{`"${
              academicYear ? academicYear : "No Academic Year"
            }"`}</span>
          </h2>
        </div>
      </div>
      <div className="col-lg-7 col-md-12 p-3 ">
        <div className="school-details py-2">
          <div className="col">
            {!isLoading ? (
              <div className="d-wrapper">
                <div className="row py-2">
                  {stageData.length > 0 ? (
                    <div className="col-lg-12 col-md-12">
                      <Accordion defaultActiveKey="0">
                        {stageData?.map((item, index) => {
                          return (
                            <>
                              <Accordion
                                className="table-div text-nowrap  mb-4"
                                style={{
                                  position: "relative",
                                  zIndex: 0,
                                }}
                              >
                                <div className="">
                                  <Accordion.Toggle
                                    key={index}
                                    as="div"
                                    variant="link"
                                    eventKey={index + 1}
                                  >
                                    <div className="px-3 py-1 d-flex align-items-center justify-content-between medium-large-text font-color-primary py-2">
                                      {item.stage}
                                    </div>
                                  </Accordion.Toggle>
                                  <Card.Text>
                                    <>
                                      {item.Grades.length > 0 ? (
                                        <>
                                          {item?.Grades?.map((g) => {
                                            return (
                                              <div
                                                className="row px-3"
                                                key={g.id}
                                              >
                                                <div className="d-flex align-items-center justify-content-between border-top py-3">
                                                  <div className="d-flex gap-3 align-items-center justify-content-start ">
                                                    <span className="">
                                                      <strong>
                                                        {g?.title}
                                                      </strong>
                                                    </span>
                                                    {g.ClassRooms.map(
                                                      (room) => {
                                                        return (
                                                          <>
                                                            <div key={room.id}>
                                                              <Dropdown>
                                                                <p
                                                                  className="badge badge-primary"
                                                                  style={{
                                                                    marginBottom: 0,

                                                                    textTransform:
                                                                      "none",
                                                                  }}
                                                                >
                                                                  {truncateClassName(
                                                                    room.name,
                                                                    17
                                                                  )}
                                                                </p>
                                                                <Dropdown.Toggle
                                                                  as={
                                                                    CustomToggle
                                                                  }
                                                                  variant="none"
                                                                  id="dropdown-basic"
                                                                  style={{
                                                                    position:
                                                                      "relative",
                                                                  }}
                                                                ></Dropdown.Toggle>

                                                                <Dropdown.Menu
                                                                  className="dropdown-menu-right account"
                                                                  style={{
                                                                    position:
                                                                      "fixed",
                                                                    top: -10,
                                                                    zIndex: 999,
                                                                  }}
                                                                >
                                                                  <Dropdown.Item
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      editRoom(
                                                                        room
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      display:
                                                                        !Util.hasPermission(
                                                                          "CLASSROOM_EDIT"
                                                                        ) &&
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    <i className="fa fa-edit fa-fw"></i>
                                                                    {t("Edit")}{" "}
                                                                    '{room.name}
                                                                    '
                                                                  </Dropdown.Item>
                                                                  <Dropdown.Item
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      setShowDeleteConfirmation(
                                                                        true
                                                                      );
                                                                      setDeleteRoomId(
                                                                        room.id
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      display:
                                                                        !Util.hasPermission(
                                                                          "CLASSROOM_DELETE"
                                                                        ) &&
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    <i
                                                                      className="fa fa-trash-o fa-fw"
                                                                      aria-hidden="true"
                                                                    ></i>
                                                                    {t(
                                                                      "Delete"
                                                                    )}{" "}
                                                                    '{room.name}
                                                                    '
                                                                  </Dropdown.Item>
                                                                  <Dropdown.Item
                                                                    onClick={() => {
                                                                      history.push(
                                                                        {
                                                                          pathname: `/teacher`,
                                                                          roomData:
                                                                            {
                                                                              room,
                                                                              g,
                                                                            },
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    <i
                                                                      className="fa fa-book fa-fw"
                                                                      aria-hidden="true"
                                                                    ></i>
                                                                    {t(
                                                                      "Teachers"
                                                                    )}
                                                                  </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                              </Dropdown>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                  {g.ClassRooms.length ===
                                                    0 && (
                                                    <>
                                                      <p className="text-muted text-center p-0 m-0">
                                                        {" Click here to add "}
                                                      </p>

                                                      <div className="move-icon">
                                                        {" "}
                                                        <EastSharpIcon
                                                          fontSize={"20px"}
                                                        />
                                                      </div>
                                                    </>
                                                  )}
                                                  <div>
                                                    <button
                                                      className={`${
                                                        Util.hasPermission(
                                                          "CLASSROOM_ADD"
                                                        )
                                                          ? "link-button"
                                                          : "no-permission"
                                                      }`}
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        addRoom(g.id);
                                                      }}
                                                    >
                                                      <i className="fas fa-plus mr-1"></i>{" "}
                                                      {t("Add Room")}
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {Util.hasPermission(
                                            "CLASSROOM_ADD"
                                          ) && (
                                            <div className="col-lg-12">
                                              <div className="text-muted">
                                                {t(
                                                  "No grades found in this stage. Add new grade:"
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  </Card.Text>
                                </div>
                              </Accordion>
                            </>
                          );
                        })}
                      </Accordion>
                    </div>
                  ) : (
                    <span className="small text text-center">
                      {t(
                        "There are no Section Structure. Click the 'Add Level' button to create one."
                      )}
                    </span>
                  )}
                </div>

                <Modal
                  show={showRoomModal}
                  onHide={closeRoomModal}
                  animation={true}
                  centered={true}
                  className="modal-view"
                  onShow={() => {
                    setIsClickedOnce(false);
                  }}
                >
                  <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                      {roomId == 0 ? t("Register New Room") : t("Edit Room")}
                    </h6>

                    <button className="hidden-btn" onClick={closeRoomModal}>
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                  <Modal.Body>
                    {!isLoading ? (
                      <div className="input-filed text-start">
                        <label className="basic-text required">
                          {t("Room Name")}
                        </label>
                        <input
                          className={`form-control custom-input ${
                            roomTitleError.length > 0 && "input-error"
                          }`}
                          type="text"
                          value={roomTitle}
                          onChange={(e) => {
                            setRoomTitle(e.target.value);
                            setRoomTitleError("");
                          }}
                        />
                        {roomTitleError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {roomTitleError}
                          </span>
                        )}
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn custom-primary"
                      onClick={handleRoomSave}
                    >
                      {t("Save")}
                    </button>
                  </Modal.Footer>
                </Modal>

                <ModalComponent
                  show={showDeleteConfirmation}
                  onHide={() => {
                    setShowDeleteConfirmation(false);
                    setDeleteRoomId(null);
                  }}
                  title={"Delete"}
                  size="sm"
                  onCancel={t("Cancel")}
                  onConfirm={t("Confirm")}
                  onCancelClick={() => {
                    setShowDeleteConfirmation(false);
                    setDeleteRoomId(null);
                  }}
                  onConfirmClick={handleDelete}
                >
                  <span>
                    {t("Are you Sure to delete this")}{" "}
                    {deleteRoomId && t("Room")}
                  </span>
                </ModalComponent>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(SectionStructure);
