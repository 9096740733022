const Const = {
  KEY_USER: "schooltalks_user",
  KEY_SELECTED_SCHOOL: "selected_school",
  KEY_ROLE_IN_SELECTED_SCHOOL: "user_role_in_selected_school",
  KEY_PERMISSIONS: "user_permissions",
  KEY_IS_PARENT_MODE: "parent_mode",
  KEY_IS_SELECTED_STUDENT:"selected_student",
  KEY_REGISTERED_USER:"registered_user",
  KEY_FORGOT_PASSWORD_USER:"forgot_password_user",
  KEY_ACADEMIC_YEAR:"selected_academic_year",
  fontfamily:'Public Sans',
  primaryColor:'#f07a80'
};
export default Const;
