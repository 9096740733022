import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/assets/scss/main.scss";
import "./assets/assets/scss/color_skins.scss";
import "../node_modules/font-awesome/scss/font-awesome.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import i18n from "./i18nextConf";
import { I18nextProvider } from "react-i18next";
import ErrorBoundary from "./ErrorBoundry";

const store = createStore(reducers, applyMiddleware(thunk));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="46507002971-itr8egh96pngoeeegnsnfi547k52pnau.apps.googleusercontent.com" >
  {/* <GoogleOAuthProvider clientId="88435559780-oe0lruvcknqf467s43stb03f4i63fqve.apps.googleusercontent.com" > */}
    <Provider store={store}>
      <Suspense
        fallback={
          // <div className="  vh-100 d-flex justify-content-center align-items-center">
          //   <Loader />
          // </div>
          "..."
        }
      >
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <ErrorBoundary>
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </ErrorBoundary>
          </I18nextProvider>
        </BrowserRouter>
      </Suspense>
    </Provider>
  </GoogleOAuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
